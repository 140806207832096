.HostCardRedirect {
  width: 268px;
  margin: 5px;
}

.Title {
  font-size: medium;
  font-weight: 700;
}

