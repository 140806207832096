// Multiselect container styles
.custom-multiselect {
  .multiSelectContainer {
    background-color: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
  }

  // Option container styles
  .optionContainer {
    background-color: #fff;
    border: 1px solid #c8c8c8;
    color: #333;
  }

  // Individual option styles
  .option {
    background-color: #fff;
    color: #f68a20;
    border: 1px solid #fff;

    &:hover {
      background-color: #f68a20;
      color: #fff;
    }
  }

  // Selected item chip styles
  .chip {
    background-color: #f68a20;
    border-radius: 4px;
    color: #fff;
  }
  // "No options available" message styles
  .emptyRecordMsg {
    background-color: #f68a20;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
  }
}
