.house-rules-container {
  text-align: center;
  max-width: 600px;
  margin: auto;
  padding: 20px;

  .rule-card {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .icon {
      color: #000;
    }
    .houseDivider {
      height: 50px;
      width: 5px;
      background-color: #f68a20;
      margin-left: 12px;
      margin-right: 12px;
    }

    .text {
      font-size: 1rem;
      text-align: left;
      width: 80%;
    }
  }

  // Responsive styling for smaller screens
  @media (max-width: 768px) {
    .title {
      font-size: 1.25rem;
    }

    .rule-card {
      padding: 0.75rem;
      .houseDivider {
        height: 30px;
        width: 3px;
        background-color: #f68a20;
        margin-left: 8px;
        margin-right: 8px;
      }

      .text {
        font-size: 0.875rem;
      }
    }
  }
}
