/* General Container */
.filter-sidebar-container {
  width: 18rem; /* equivalent to w-72 */
  padding: 1.5rem; /* equivalent to p-6 */
  background-color: transparent !important;
  border-radius: 0.5rem; /* equivalent to rounded-lg */
  //   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* equivalent to shadow-lg */
}

/* Spacing for children elements */
.filter-sidebar-space-y-6 {
  margin-bottom: 1.5rem; /* equivalent to space-y-6 */
}

.filter-sidebar-space-y-4 {
  margin-bottom: 1rem; /* equivalent to space-y-4 */
}

.filter-sidebar-space-y-2 {
  margin-bottom: 0.5rem; /* equivalent to space-y-2 */
}

/* Heading */
.filter-sidebar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.filter-sidebar-header h2 {
  font-size: 1.25rem; /* equivalent to text-xl */
  font-weight: 600; /* equivalent to font-semibold */
  color: #1f2937; /* equivalent to text-gray-800 */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Input Fields */
.filter-sidebar-input,
.filter-sidebar-select {
  width: 100%;
  padding-left: 2.25rem; /* to create space for icons */
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem; /* equivalent to rounded-lg */
  border: 1px solid #d1d5db; /* equivalent to border-gray-300 */
  transition: all 0.3s;
}

.filter-sidebar-input:focus,
.filter-sidebar-select:focus {
  outline: none;
  border-color: #f68a20; /* equivalent to focus:border-blue-500 */
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5); /* equivalent to focus:ring-2 */
}

/* Icon Styling */
.filter-sidebar-input-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af; /* equivalent to text-gray-400 */
  width: 1rem;
  height: 1rem;
}

/* Buttons */
.filter-sidebar-button {
  width: 100%;
  padding: 0.625rem 1rem;
  background-color: #f68a20; /* equivalent to bg-blue-600 */
  color: white;
  font-weight: 500; /* equivalent to font-medium */
  border-radius: 0.5rem; /* equivalent to rounded-lg */
  transition: background-color 0.2s, transform 0.2s;
}

.filter-sidebar-button:hover {
  margin-top: 20px;
  background-color: #f68a20; /* equivalent to hover:bg-blue-700 */
  transform: scale(1.02); /* equivalent to hover:scale-[1.02] */
}
