/* Swiper pagination bullets for SpeakersSection */
.speakers-swiper .swiper-pagination-bullet {
  width: 13px;
  height: 3px;
  background-color: #d9d9d9 !important;
  opacity: 1;
  border-radius: 2px;
}

/* Active bullet */
.speakers-swiper .swiper-pagination-bullet-active {
  width: 25px;
  height: 5px;
  border-radius: 4px;
  background-color: #f68a20 !important; /* Change to your desired color for active bullet */
  transform: scale(1.2); /* Make the active bullet larger */
}

/* Adjust spacing between bullets */
.speakers-swiper .swiper-pagination {
  bottom: -20px; /* Adjust the position */
}

.speakers-swiper .swiper-pagination-bullet {
  margin: 0 5px; /* Adjust the spacing between bullets */
}

/* Speaker card styling */
.speaker-card {
  border: 1px solid #f68a20;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  .speaker-image {
    width: 100px;
    height: 100px;

    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

/* destop */
@media (min-width: 1100px) {
  .swiper-slide {
    width: 900px;
  }
  .speaker-card {
    width: 360px !important;
    height: 180px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
  }
  .speaker-image {
    border-radius: 50%;
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .speaker-img-text {
    width: 150px !important;
    // background-color: #f68a20;
    .speaker-img-Title {
      font-size: 15;
      font-weight: 900;
      text-align: justify;
      color: #000;
      margin-bottom: 7px;
    }
    .speaker-img-Role {
      font-size: 13px;
      font-weight: 700;
      width: 100%;
      text-align: start;
      // text-align: justify;
      // letter-spacing: 1px;
      // word-spacing: 1px;
      color: #424242;
    }
    .speaker-img-description {
      width: 100%;
      font-size: 11px;
      text-align: start;
      color: #000;
      word-wrap: inherit;
      // letter-spacing: 1px;
      word-spacing: 0.3px;
    }
  }
}

/* tablet */

@media screen and (max-width: 768px) {
}

/* smart phone */
@media (min-width: 169px) and (max-width: 480px) {
  .speaker-card {
    width: 220px !important;
    height: 130px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
  }
  .speaker-image {
    border-radius: 50px;
    width: 50px !important;
    height: 50px !important;

    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .speaker-img-text {
    width: 150px;
    // background-color: #f68a20;
    .speaker-img-Title {
      font-size: 13;
      font-weight: 900;
      // text-align: justify;
      color: #000;
      margin-bottom: 7px;
      word-spacing: 0;
    }
    .speaker-img-Role {
      font-size: 13px;
      font-weight: 700;
      width: 100%;
      text-align: start;
      // text-align: justify;
      // letter-spacing: 1px;
      // word-spacing: 1px;
      color: #424242;
    }
    .speaker-img-description {
      width: 100%;
      font-size: 11px;
      text-align: start;
      color: #000;
      word-wrap: inherit;
      // letter-spacing: 1px;
      word-spacing: 0.2px;
    }
  }
}
