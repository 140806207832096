.count-down-box {
  width: 100%;
  display: flex;
  justify-content: center;

  &-container {
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-wrapper {
    width: 90px;
    height: 90px;
    border: 3px solid#f68a20;
    border-radius: 10px;
    padding-top: 8px;
  }
  &-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16;
    font-family: poppins;
    font-weight: 700;
    color: black;
  }
  &-time {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16;
    font-family: poppins;
    color: black;
  }
}

@media (max-width: 500px) {
  .count-down-box {
    width: 100%;
    display: flex;
    justify-content: center;

    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-wrapper {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      border: 2px solid #f68a20;
    }
    &-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-family: poppins;
      font-weight: 600;
      color: black;
    }
    &-time {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8px;
      font-family: poppins;
      color: black;
    }
  }
}
