.upload-booth-exhibibtion-logo-btn-container {
  position: relative;
  width: 200px;
  margin: 0px auto;

  input[type="file"] {
    display: none;
  }
}
.upload-booth-exhibibtion-logo-btn {
  position: absolute;
  color: #f68a20;
  font-size: 0.875rem;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  // gap: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-top: 1%;
}
.upload-booth-exhibibtion-logo-item {
  display: flex;
  align-items: center;
  gap: 4;
}
