.dashboard-container {
  // background-color: #f4f6f9; /* Light background for contrast */
  min-height: 100vh;
  padding: 20px;
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
}

.filter-panel {
  position: sticky;
  top: 20px; /* Keep the filter panel fixed when scrolling */
  height: calc(100vh - 60px); /* Adjust height to account for the header */
  overflow-y: auto; /* Allow scrolling if content exceeds height */
  padding: 15px; /* Add padding for spacing */
  background-color: #ffffff; /* Background color to separate from main container */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.filter-card {
  background: #ffffff; /* Card background */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Inner padding for content */
  margin-bottom: 15px; /* Spacing between cards */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Shadow for card elevation */
}

input,
select {
  border-radius: 4px; /* Rounded input and select */
  border: 1px solid #ced4da; /* Border color for inputs */
  padding: 10px; /* Inner padding for comfort */
  width: calc(100% - 20px); /* Full width minus padding */
  margin-bottom: 15px; /* Space between input fields */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

input:focus,
select:focus {
  border-color: #66afe9; /* Highlight border color on focus */
  outline: none; /* Remove default outline */
}

.close-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fcceba; /* Soft close button background */
  cursor: pointer; /* Pointer cursor */
  border: none; /* Remove default border */
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.close-button:hover {
  background-color: #f8b400; /* Darker color on hover */
}

.date-picker-spacing {
  margin-left: 20px; /* Adjust this value as needed for spacing */
}

/* Add hover effects to filter cards */
.filter-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Elevate card on hover */
}

/* General button styles for consistency */
.button {
  background-color: #007bff; /* Primary button color */
  color: #ffffff; /* Text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 15px; /* Inner padding */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.mb-4 {
  display: flex;
  justify-content: space-between; // Space between the button sections
  align-items: center; // Center items vertically
}

.btn-success,
.btn-info {
  margin-left: 8px; // Add some spacing between the text and buttons
}
