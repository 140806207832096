.textarea-chatgpt {
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  font-size: 14px;
}

.button-chatgpt {
  padding: 10px 20px;
  background-color: #1d388c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-chatgpt:disabled {
  background-color: #ddd;
}

.h2-chatgpt {
  color: #333;
}
