//
// authentication.scss
//
// .bg-pattern {
//     // background-image: url("../images/bg.jpg");
//     background-image: url("../../../images/bg.jpg");
//     background-size: cover;
//     background-position: center;
// }

// .auth-logo {
//     &.logo-light {
//         display: $display-none !important;
//     }

//     &.logo-dark {
//         display: $display-block !important;
//     }
// }

//my customize login
.auth-participant-container {
  width: 100%;
  height: 100vh !important;
  background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  .auth-participant-wrapper {
    width: 100em;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #f68a20;
  }
}
.participant--form--slider-container {
  height: 100%;
  width: 55em;
  .participant--form--slider-container--first {
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .participant--form--slider-container--second {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .participant--form--slider-container--second {
      width: 35em;
      // background-color: #f10d11 !important;
    }
    .participant--form--header {
      width: 35em;
    }
  }
}

.participant--slider-container {
  height: 50em;
  width: 40em;
}
.participant--slider-wrapper {
  height: 100%;
  width: 100%;
  display: "flex";
  // justify-content: "center";
  // align-items: "center";
  // padding-top: 6%;
  // margin-left: 10%;
  user-select: "none";
  //   background-color: "#F68A20" !important;
}

.swipper--content {
  width: 80%;
  height: 80%;
}
/* Custom pagination bullet color */
.swiper-pagination-bullet {
  background: white !important;
}

/* Custom active pagination bullet color */
.swiper-pagination-bullet-active {
  background: #f68a20 !important;
}

.successFullMessage-container {
  width: 100%;
  height: 100vh !important;
}

@media (min-width: 768px) {
  .gradient-form {
    // height: 100vh !important;
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
