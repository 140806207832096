.Image_Fit {
  object-fit: cover;
  // object-position: center;
}

// sidebar Event
.event-sidebar-index {
  z-index: 99 !important;
}
.event-sidebar-text-color {
  color: #fff !important;
}
// .event-sidebar-text-color:hover {
//   color: #f68a20 !important;
// }

// card border style
.card-brd {
  border-radius: 8px;
  border: 1px solid rgba(73, 80, 87, 0.2);
  // object-fit: contain !important;
}

.bg-color-tab {
  background-color: rgba(73, 80, 87, 0.15);
}
/******************
* Register as page 
*******************/

.customize---topBar {
  // background-color: #f3f3f4 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 2px 0 5px 5px rgba(0, 0, 0, 0.2);
}

.requrstDemo---topBar {
  // background-color: #f3f3f4 !important;
  background-color: #fff !important;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  box-shadow: 0px 29px 60px 16px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 29px 43px 16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 29px 43px 16px rgba(0, 0, 0, 0.75);
}

.Specification__Card__picture__img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

/******************
* my events
*******************/
.myEvent-card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.05) !important;
}

//become host
.btnMyEventHostEvent {
  border-radius: 10px;
  background-color: $primary !important;
  // color: $white;
}
.btnMyEventHostEvent:hover {
  background-color: $black !important;
}
// create event
.btnMyEventCreateEvent {
  border-radius: 10px;
  background-color: $black !important;
  // color: $white;
}
.btnMyEventCreateEvent:hover {
  background-color: rgb(146, 145, 145) !important;
}

// myevent filter buton
.btnMyEventFilterBtn1 {
  border-radius: 20px;
  background-color: #626c76;
  color: #ffff;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.btnMyEventFilterBtn1:hover {
  border-radius: 20px;
  background-color: white;
  color: #626c76;
  border: 2px solid #626c76;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.btnMyEventFilterBtn2 {
  border-radius: 20px;
  background-color: white;
  color: #626c76;
  border: 2px solid #626c76;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.btnMyEventFilterBtn2:hover {
  border-radius: 20px;
  background-color: #626c76;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.dashboard-heading-container-card {
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 10px;
  margin-bottom: 10px;
  .dashboard-heading-event-name {
    display: flex;
    align-items: center;
    width: 57%;
  }
}

// view all events
.dashboard-container-card {
  width: 100%;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid rgb(226, 226, 226);
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  .dashboard-container-img-card {
    width: 14.75rem;
    height: 10.1rem;
    position: relative;
    img {
      position: absolute;
      width: 14.75rem;
      height: 10.1rem;
      border-radius: 10px;
      // object-fit: contain;
    }
  }
  .dashboard-wrapper {
    border: 3px solid green;
    width: 56%;
    display: flex;
    .dashboard-event-content {
      width: 56%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      border: 3px solid red;
      h3 {
        flex-wrap: wrap;
      }
      // background-color: red;
    }
  }

  .dashboard-event-status {
    padding: 10px;
    width: 18%;
    background-color: blue;
  }
  .dashboard-event-action {
    padding: 10px;
    width: 18%;
    background-color: green;
  }
}
.MyEvent_Parent_div {
  position: relative;
  width: 15.75rem;
  height: 13.1rem;
  // background-color: red;
  // height: 17em;
}
.MyEvent_image_div {
  width: 15.75rem !important;
  height: 13.1rem !important;
  object-fit: cover;
  // display: flex;
  // justify-content: center !important;
  // align-items: center ;
  // overflow: hidden;
  img {
    border-radius: 10px;
    object-fit: cover;
  }
}

// My event banner ribbon conference styles

// My event banner ribbon social styles

// My event banner ribbon expo styles

/****************************************
* Create Events
*****************************************/

//we will see this after
.Specify__Card__container {
  display: flex;
  // flex-wrap: wrap;
}
.Specify__Card {
  width: 248px;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
}
.Specify__Card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25) !important;
}
.Specify__Card__img {
  // width: 100%;
  // height: 100%;
  width: 248px;
  height: 140px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

// to rebuild this below code

.event__form__banner_container {
  position: relative;
}

.event__form__banner_img {
  width: 850px;
  height: 350px;
  border: 2px solid #1a87a3 !important;
  border-radius: 10px;
}

.btnUploadEventBanner {
  position: absolute;
  top: 40%;
  bottom: 40%;
  left: 40%;
  right: 40%;
  background-color: transparent !important;
}
.form-create-event {
  width: 850px;
}
.event__input__form__Creation {
  width: 100%;
  max-width: 100%;
}

.section---banner---container {
  position: relative;
  margin-right: 2% !important;
  border-radius: 10px;
  border: 1px dashed #626c76;
}
.section---banner---container---img {
  border-radius: 10px;
}

.profile-container-wrapper-left {
  display: none;
}
.profile-container {
  background-color: "white" !important;
  border-radius: 10px;
  box-shadow: 0px 0px 99px 0px rgba(0, 0, 0, 0.1);
}

.profile-container-left {
  position: fixed !important;
  width: 20%;
  // top: 0;
  background-color: "white" !important;
  border-radius: 10px;
  box-shadow: 0px 0px 99px 0px rgba(0, 0, 0, 0.1);
  .profile-container-left-option-1 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
  }
  .profile-container-left-option-1:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-2 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-2:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-3 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-3:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
  .profile-container-left-option-4 {
    padding: 4px 0px 4px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // margin
  }
  .profile-container-left-option-4:hover {
    background-color: rgb(221, 221, 221) !important;
    color: white;
  }
}

.profile-container-Title {
  font-size: 20px;
  font-weight: 700;
}

.profile-container-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #bfc4c9;
}

.profile-img-container {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
  background-color: #f68a20;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
}
.profile-container-main {
  width: 90%;
}

.profile-form-title {
  font-size: 20px;
  font-weight: 700;
}

//booth styling
.event--booth--contanier {
  margin-top: 1.2em;
  // background-color: #1a87a3;
}

// set-event-right-navigation
.set-event-rightbar-menu {
  width: 100%;
  height: 90vh;
}
.set-event-rightbar-menu-card {
  width: 100%;
  // position: fixed;
  // background-color: #fff;
}
.set-event-rightbar-content-hearder {
  width: 100%;
}

// .set-event-rightbar-content {
//   width: 100vw;
//   height: 100vh;
//   background-color: #fff !important;
// }

.webinar-backstage {
  position: absolute;
  margin-top: 5%;
  height: 100vh;
  // background-color: #f3f3f4;
  // background-color: rgb(19, 18, 22);
  background-color: #f9c393;
}

// sponsors reception
.sponsors-list-sponsor {
  width: 120px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 1200px) {
  .webinar-backstage {
    position: absolute;
    margin-top: 10%;
    height: 100vh;
    background-color: #f3f3f4;
    // background-color: rgb(19, 18, 22);
  }
  // sponsors reception
  .sponsors-list-sponsor {
    margin-top: 20px;
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

/* Wrapper around PhoneInput for consistent appearance */
.phone-input-container {
  position: relative;
  display: inline-block;
  width: 100%; /* or 100% if you want it to be responsive */
  border: 1px solid #ced4da; /* Match your form inputs' border */
  border-radius: 7px;
  overflow: hidden;
  padding-left: 7px; /* Ensures nothing spills out of the border-radius */
}

/* Style adjustments for the PhoneInput */
.custom-phone-input .PhoneInputInput {
  border: none; /* Remove the default border */
  outline: none; /* Remove the outline on focus */
  box-shadow: none; /* Remove any default shadow */
  height: 38px; /* Match the height of your other inputs */
  padding-left: 3px; /* Make space for the flag inside the input */
  /* Adjust font properties as needed to match other inputs */
}

/* Adjust the flag and dropdown button positioning */
.custom-phone-input .PhoneInputCountrySelect,
.custom-phone-input .PhoneInputCountrySelectArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1px; /* Adjust as needed to align the flag */
  height: 36px; /* Adjust the flag height to fit within the input */
  display: flex;
  align-items: center;
}

.custom-phone-input .PhoneInputCountrySelectArrow {
  display: none; /* Optionally hide the dropdown arrow for a cleaner look */
}

/* Ensure the flag icon fits nicely */
.custom-phone-input .PhoneInputFlag {
  width: auto;
  height: 100%;
}

.qrCode-page-container {
  width: 100%;
  height: 100vh !important;
  // background-color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

//any screen
.event-reception-details {
  display: flex;
  margin-bottom: 64px;
  padding-bottom: 64px;
  border-bottom: 0.4px dashed #d2d2d2;

  .event-reception-welcome {
    width: 68%;
    padding-right: 36px;
    text-align: justify;
    border-right-color: 2px solid #fff;
  }

  .event-reception-upcoming {
    width: 32%;

    .event-reception-sessions {
      width: 100%;
    }
  }

  // sponsors reception
  .sponsors-list-sponsor {
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

// smart phone sizes
@media (min-width: 169px) and (max-width: 480px) {
  .webinar-backstage {
    position: absolute;
    margin-top: 17%;
    // height: 100vh;
    background-color: #f3f3f4;
    // background-color: rgb(19, 18, 22);
  }
  .event-reception-banner {
    width: 100% !important;
  }
  .event-reception-content {
    width: 80vw !important;
    object-fit: contain;
  }
  // .event-reception-details {
  //   width: 100% !important;
  //   display: block;
  //   border: 3px solid red !important;
  // }
  // .event-reception-upcoming {
  //   width: 100% !important;
  //   display: block;
  //   border: 3px solid purple !important;
  // }
  .event-reception-details {
    display: unset;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 0.4px dashed #d2d2d2;

    .event-reception-welcome {
      width: 100%;
      text-align: justify;
      padding: 0px;
      border-right-color: 2px solid #fff;
      .event-reception-text-details {
        width: 100%;
        padding: 0px;
      }
    }

    .event-reception-upcoming {
      width: 100%;
      padding-bottom: 20%;
      .event-reception-sessions {
        width: 100%;
      }
    }
  }
  .event-reception-marquee-container {
    width: 100%;
    bottom: 70px;
    .event-reception-marquee-item {
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .event-reception-bottom-space {
    height: 100px;
    width: 100%;
  }
}
