.lotties-login-container-exhibition {
  margin-top: 20%;
  margin-bottom: 2%;
  object-fit: cover;
}
.lotties-login-text {
  font-size: 14px;
  font-weight: bold;
  color: #fff !important;
}

.lotties-login-container-conference {
  // width: 100%;
  // height: 100%;
  width: 17em;
  height: 17em;
  margin-bottom: 2em;
  // background-color:red !important
}

.lotties-login-container-webinar {
  width: 17em;
  height: 17em;
  margin-bottom: 2em;
}
.lotties-login-webinar-caption {
  // margin-top: -6%;
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .lotties-login-container-exhibition {
    margin-top: 20%;
    margin-bottom: 2%;
    object-fit: cover;
  }
  .lotties-login-text {
    font-size: 14px;
    font-weight: bold;
    color: #fff !important;
  }

  .lotties-login-container-conference {
    width: 8em;
    height: 8em;
    margin-bottom: 1em;
    // background-color:red !important
  }

  .lotties-login-container-webinar {
    // width: 100%;
    // height: 100%;
    width: 8em;
    height: 8em;
    margin-bottom: 1em;
  }
  .lotties-login-webinar-caption {
    // margin-top: -6%;
  }
}
