/* Banner styles */
.banner-wrapper-request-demo {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: linear-gradient(
    135deg,
    #f68a20,
    #ffc444
  ); /* Darker to lighter orange */
  padding: 20px 0;
  text-align: center;
  p {
    width: 55%;
    margin: 0px auto;
  }
}

.banner-request-demo {
  color: white;
  font-size: 1.5rem;
}

.banner-request-demo p {
  margin-top: 0px;
  font-size: 1rem;
}

/* Main container styles */

.request-demo-column {
  padding: 15px;
}

.required-star {
  color: red;
  margin-left: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.custom-phone-input-request-demo,
select {
  // border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  transition: border-color 0.2s;
  // &:hover {
  //   background-color: #f68a20;
  // }
}
option:hover {
  background-color: #f68a20;
}
.custom-phone-input-request-demo:focus,
select:focus {
  border-color: #f68a20;
  // outline: none;
}

.custom-phone-input-request-demo {
  border: 1px solid #ddd;
  outline: none;
  box-shadow: none;
  height: 38px;
  padding-left: 10px;
  background-color: #fff;
  .PhoneInputInput {
    border: none;
  }
}

/*Packages && Request a Demo Section*/

.request-demo-form {
  width: 100%;
  &-container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffff;

    // Responsive layout
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    &-left {
      // flex: 1;
      width: 500px;
      min-height: 800px;
      background: linear-gradient(135deg, #f68a20, #ffc444);
      padding: 1.5rem;
      border-radius: 10px;
      color: white;
      font-family: poppins;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &-title {
        font-size: 1.5rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin: 0px auto;
        margin-bottom: 1rem;
      }
      &-faq-item {
        padding: 1rem;

        margin-bottom: 0.5rem;
        cursor: pointer;
        &.active {
          background: white;
          color: black;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        &-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          font-weight: bold;
          span {
            color: white; // Default color for both title and icon
          }
        }

        &-faq-content {
          margin-top: 1.5rem;
          user-select: none;
          &hidden {
            display: none;
          }

          li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    &-right {
      width: 100%;
      h4 {
        font-size: 1.5rem;
        color: #333;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
}

/* FAQ Section layout section */
.faq {
  &-demoSection {
    background-color: #fff;
    h2 {
      font-family: poppins;
      font-size: 30px;
      font-weight: 900;
      margin: 0px auto;
      padding-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      color: #535252;
    }
  }
  &-container {
    &-main {
      max-width: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px 20px 20px 20px;
      margin: 0px auto;
      margin-bottom: 5%;
      background-color: #fff;
      font-family: poppins;
      border-radius: 8px;
      // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    &-left {
      width: 500px;
    }
    &-right {
      width: 450px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      margin-top: 30px;
      img {
        width: 100%;
      }
    }
  }
}

//events card
.req-card {
  background-color: #f2f2f2;
  &-title {
    font-family: poppins;
    font-size: 40px;
    text-align: center;
    // font-weight: 700;
    max-width: 80%;
    margin: 0 auto;
    color: black;
  }
}
