.ticket-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ticket-wrapper {
  width: 48%;
  float: left;
  padding: 0 20px;
  background: #fff;
  overflow: hidden;
  margin: 10px;
}

.ticket-container .item-right,
.ticket-container .item-left {
  float: left;
  padding: 20px;
}

.ticket-container .item-right {
  padding: 79px 50px;
  margin-right: 20px;
  width: 25%;
  position: relative;
  height: 239px;
}

.ticket-container .item-right .up-border,
.ticket-container .item-right .down-border {
  padding: 14px 15px;
  background-color: #f2f2f2;
  border-radius: 50%;
  position: absolute;
}

.ticket-container .item-right .up-border {
  top: -8px;
  right: -35px;
}

.ticket-container .item-right .down-border {
  bottom: -8px;
  right: -35px;
}

.ticket-container .item-right .num {
  font-family: "poppins";
  font-size: 60px;
  text-align: center;
  color: #111;
}

.ticket-container .item-right .day {
  text-align: center;
  font-family: "poppins";
  font-size: 25px;
}

.ticket-container .item-left {
  width: 71%;
  padding: 34px 0px 19px 46px;
  border-left: 3px dotted #999;
}

.ticket-container .item-left .title {
  color: #111;
  font-family: "poppins";
  font-size: 34px;
  margin-bottom: 12px;
}

.ticket-container .item-left .sce {
  margin-top: 5px;
  display: block;
}

.ticket-container .item-left .sce .icon,
.ticket-container .item-left .sce p,
.ticket-container .item-left .loc .icon,
.ticket-container .item-left .loc p {
  float: left;
  word-spacing: 5px;
  letter-spacing: 1px;
  color: #888;
  margin-bottom: 10px;
}

.ticket-container .item-left .loc {
  display: block;
}

.fix {
  clear: both;
}

/* Media query for screens between 768px and 1100px */
@media (max-width: 1100px) {
  .ticket-wrapper {
    width: 80%; /* Keep similar width but slightly reduce for smaller screens */
    padding: 10px;
  }

  .ticket-container .item-right {
    padding: 50px 20px;
    margin-right: 10px;
    width: 30%; /* Adjust width slightly for smaller screens */
  }

  .ticket-container .item-left {
    width: 65%; /* Keep structure intact */
    padding: 20px 10px;
  }

  .ticket-container .item-right .num {
    font-size: 45px;
  }

  .ticket-container .item-right .day {
    font-size: 20px;
  }

  .ticket-container .item-left .title {
    font-size: 28px;
  }
}

/* Media query for mobile screens (below 768px) */
@media (max-width: 768px) {
  .ticket-wrapper {
    width: 95%; /* Reduce the width slightly to fit the mobile screen */
    padding: 10px;
  }

  .ticket-container .item-right {
    width: 30%; /* Keep the date section smaller */
    padding: 20px 0;
  }

  .ticket-container .item-right .num {
    font-size: 35px;
  }

  .ticket-container .item-right .day {
    font-size: 18px;
  }

  .ticket-container .item-left {
    width: 65%; /* Keep the layout and align left */
    padding: 10px;
  }

  .ticket-container .item-left .title {
    font-size: 22px;
  }

  .ticket-container .item-left .sce {
    margin-top: 2px;
  }

  .ticket-container .item-left .sce p {
    font-size: 14px;
  }
}

/* Media query for smaller mobile screens (below 576px) */
@media (max-width: 576px) {
  .ticket-wrapper {
    width: 100%; /* Stack content vertically */
    padding: 10px;
  }

  .ticket-container {
    flex-direction: column; /* Stack the items */
  }

  .ticket-container .item-right {
    width: 100%;
    padding: 20px 0;
    text-align: center; /* Center the date on smaller screens */
  }

  .ticket-container .item-right .num {
    font-size: 28px;
  }

  .ticket-container .item-right .day {
    font-size: 16px;
  }

  .ticket-container .item-left {
    width: 100%;
    padding: 10px;
    border-left: none; /* Remove border for mobile */
    border-top: 3px dotted #999; /* Add top border for mobile */
  }

  .ticket-container .item-left .title {
    font-size: 20px;
  }

  .ticket-container .item-left .sce p {
    font-size: 12px;
  }
}
