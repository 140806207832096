.thank-you-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient(135deg, #fff, #f9f9f9);
  font-family: 'Poppins', sans-serif;
  padding: 40px 20px; /* Add horizontal padding */
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.kago-banner-image {
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.thank-you-content {
  margin: 20px 0; /* Add margin for vertical spacing */
}

.thank-you-content h2 {
  font-size: 2.8rem; /* Increased header size */
  margin-bottom: 20px;
  color: #ff6600;
  font-weight: bold;
}

.thank-you-content p {
  font-size: 1.4rem; /* Increased paragraph font size */
  margin-bottom: 40px; /* Increased bottom margin for more spacing */
}

.side-by-side-blocks {
  display: flex;
  flex-direction: row; /* Side by side on larger screens */
  justify-content: space-between; /* Evenly space blocks */
  width: 100%;
  max-width: 800px; /* Adjusted max width */
  gap: 20px;
  margin-bottom: 40px; /* Added margin to separate from the next section */
}

.block {
  padding: 40px; /* Increased padding for larger blocks */
  border-radius: 12px;
  background: white;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  border: 2px dashed #ff8c00;
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
  flex: 1; /* Ensure blocks take equal space */
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px; /* Increased top margin */
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: #ff6600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.social-icon:hover {
  background-color: #ff6600;
  color: #fff;
  transform: translateY(-2px);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .thank-you-content h2 {
    font-size: 2.2rem; /* Adjusted for smaller screens */
  }

  .thank-you-content p {
    font-size: 1.2rem; /* Smaller size on mobile */
  }

  .block {
    flex: 1; /* Allow blocks to grow */
    min-width: 100%; /* Ensures blocks are full width on mobile */
  }

  .side-by-side-blocks {
    flex-direction: column; /* Stack blocks vertically on mobile */
  }
}

.authentication-brandMark {
  margin-top: 30px; /* Increased margin for spacing */
  padding: 15px;
  font-size: 1rem;
  color: #333;
  text-align: center;
}

.brand-mark {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.brand-mark:hover {
  color: #ff6600;
}
