//my customize login
@media (min-width: 1281px) {
  .authentication-container {
    width: 100%;
    height: 100vh;
    background-color: #f9c393 !important;

    .authentication-container-form {
      width: 100%;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .authentication--wrapper {
        width: 770px;
        height: 600px;
        display: flex;

        // flex-direction: row-reverse;
        justify-content: center;
        // align-items: center;
        // gap: 3em;
        border-radius: 15px;
        background-color: #fff !important;
        // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 8px rgba(148, 148, 148, 0.5);
        // border: 2px solid rgb(95, 243, 157) !important;

        .login--form--slider-container {
          height: 100%;
          width: 50%;
          display: flex;
          justify-content: center;
          .login--form--slider-container--first {
            width: 100%;
            height: 100%;
            justify-content: center;
            display: none;
          }
          .login--form--slider-container--second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            .login-form-container {
              width: 390px;
              margin: 0 auto;
              padding: 20px;
              border-radius: 10px;
              .login-form-header {
                width: 100%;
                .login-form-logo-size {
                  width: 170px;
                  height: 75px;
                  opacity: 1 !important;
                  // object-fit: cover;
                  margin-bottom: 15px;
                }
              }
              .authentication-recaptcha {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
              }
            }
          }
        }
        // lotties
        .login--slider-container {
          height: 100%;
          width: 50%;
          display: flex;
          justify-content: start;
          .login--slider-wrapper {
            position: relative;
            height: 600px;
            width: 100%;
            display: flex;
            justify-content: center;
            // align-items: center;
            user-select: "none";
            // margin-left: -1%;
            border-radius: 15px;
            background-color: #f68a20;
          }
        }
      }
    }
    .trade-mark {
      width: 100%;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;

      &-text {
        font-size: 14;
        font-weight: bolder;
        color: black !important;
      }
    }
  }
}

// small laptop screen
@media (min-width: 1100px) and (max-width: 1280px) {
  .login-form-text {
    margin-top: 18px;
    font-size: 16px;
  }

  .authentication-container {
    width: 100%;
    height: 100vh !important;
    background-color: #f9c393 !important;

    .authentication-container-form {
      width: 100%;
      height: 95vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .authentication--wrapper {
        width: 850px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        background-color: white !important;
        box-shadow: 0px 4px 8px rgba(148, 148, 148, 0.5);

        .login--form--slider-container {
          height: 100%;
          width: 50%;
          display: flex;
          justify-content: center;
          // border: 3px solid green;
          .login--form--slider-container--first {
            width: 100%;
            height: 100%;
            justify-content: center;
            display: none;
          }
          .login--form--slider-container--second {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            .login-form-container {
              width: 390px;
              margin: 0 auto;
              padding: 11px;
              border-radius: 12px;
              .login-form-header {
                width: 100%;
                h1 {
                  font-size: 25px;
                }
                .login-form-logo-size {
                  width: 145px !important;
                  height: 71px !important;
                  margin-left: -5px !important;
                  object-fit: cover;
                }
                .form-field {
                  margin-bottom: 15px;
                }

                .form-field label {
                  display: block;
                  font-weight: bold;
                  margin-bottom: 5px;
                }

                .form-field input[type="email"],
                .form-field input[type="password"],
                .form-field input[type="text"],
                .form-field input[type="number"],
                .form-field input[type="tel"] {
                  width: 100%;
                  padding: 8px;
                  border: 1px solid #ccc;
                  border-radius: 10px;
                }

                .form-field input.error {
                  border-color: #f68a20;
                }

                .error-text {
                  color: #f68a20;
                  margin-top: 5px;
                }

                .password-input {
                  position: relative;
                }

                .password-input .eye-icon {
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  background: transparent;
                  border: none;
                  cursor: pointer;
                }

                .remember-me {
                  display: flex;
                  align-items: center;
                }

                .remember-me input[type="checkbox"] {
                  margin-right: 5px;
                }

                .login-button {
                  background-color: #f68a20;
                  color: #fff;
                  border: none;
                  border-radius: 7px;
                  padding: 8px 16px;
                  cursor: pointer;
                }

                .signup-prompt {
                  text-align: center;
                  margin-top: 15px;
                }

                .signup-prompt span {
                  font-weight: bold;
                }

                .signup-prompt a {
                  color: #f68a20;
                  margin-left: 5px;
                  text-decoration: none;
                }
              }
              .authentication-recaptcha {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
              }
            }
          }
        }

        // lotties
        .login--slider-container {
          height: 100%;
          width: 50%;
          display: flex;
          justify-content: start;
          // border: 2px solid rgb(95, 243, 157) !important;
          .login--slider-wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            // align-items: center;
            user-select: "none";
            border-radius: 15px;
            background-color: #f68a20;
            // border: 2px solid rgb(160, 68, 247) !important;
          }
        }
      }
    }
    .trade-mark {
      width: 100%;
      height: 5vh;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        font-size: 14;
        font-weight: bolder;
        color: black !important;
      }
    }
  }
}

/* Tablets Screen */
@media (min-width: 481px) and (max-width: 1099px) {
  .authentication-container {
    width: 100%;
    height: 100vh !important;
    background-color: #f9c393 !important;
    .authentication-container-form {
      width: 100%;
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .authentication-container-form {
        width: 100%;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .authentication--wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          .login--form--slider-container {
            height: 100%;
            width: 100%;
            .login--form--slider-container--first {
              display: none;
            }
          }
          .authentication-recaptcha {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
          }
        }
        .authentication-recaptcha {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
        }
      }
    }
    .trade-mark {
      width: 100%;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        font-size: 14;
        font-weight: bolder;
        color: black !important;
      }
    }
  }
  .login--slider-container {
    display: none !important;
  }
  .login-form-container {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255) !important;
  }
  .login-form-logo-size {
    width: 195px;
    height: 67px;
    // object-fit: cover;
    margin-bottom: 20px;
  }
}

// form login Input  # new #form here

.login-form-container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  // border: 1px solid #ccc;
  border-radius: 10px;
}

.login-form-header {
  // text-align: center;
  margin-top: 1.5em;
  margin-bottom: 1.2em;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-field input[type="email"],
.form-field input[type="password"],
.form-field input[type="text"],
.form-field input[type="number"],
.form-field input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.form-field input.error {
  border-color: #f68a20;
}

.error-text {
  color: #f68a20;
  margin-top: 5px;
}

.password-input {
  position: relative;
}

.password-input .eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input[type="checkbox"] {
  margin-right: 5px;
}

//smart phones
@media (min-width: 150px) and (max-width: 480px) {
  .authentication-container {
    position: "relative";
    width: 100%;
    height: 100vh;
    background-color: #f9c393 !important;
    // background-color: #f9c393 !important;

    // border: 3px solid red !important;
    display: flex;
    justify-content: center;
    align-items: center;
    .authentication--wrapper {
      // width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .login--form--slider-container {
        height: 100%;
        width: 100%;
        .login--form--slider-container--first {
          display: none;
        }
      }
    }
    .authentication-recaptcha {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .trade-mark {
      width: 100%;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        font-size: 14;
        font-weight: bolder;
        color: black !important;
      }
    }
  }
  .login--slider-container {
    display: none !important;
  }

  .login-form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
  }
  .login-form-logo-size {
    width: 11.5em;
    height: 4.7em;
    object-fit: cover;
    margin-bottom: 15px;
  }
}
