/* Swiper pagination bullets for SpeakersSection */
.speakers-swiper .swiper-pagination-bullet {
  width: 13px;
  height: 3px;
  background-color: #d9d9d9 !important;
  opacity: 1;
  border-radius: 2px;
}

/* Active bullet */
.speakers-swiper .swiper-pagination-bullet-active {
  width: 25px;
  height: 5px;
  border-radius: 4px;
  background-color: #f68a20 !important; /* Change to your desired color for active bullet */
  transform: scale(1.2); /* Make the active bullet larger */
}

/* Adjust spacing between bullets */
.speakers-swiper .swiper-pagination {
  bottom: -20px; /* Adjust the position */
}

.speakers-swiper .swiper-pagination-bullet {
  margin: 0 5px; /* Adjust the spacing between bullets */
}

/* Speaker card styling */
.speaker-card {
  border: 1px solid #f68a20;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  .speaker-image {
    width: 100px;
    height: 100px;

    margin-bottom: 15px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

/* destop */
@media (min-width: 1100px) {
  .speaker-container-main-card {
    width: 260px;
    height: 220px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    .speaker-container-main {
      .speaker-image {
        width: 70px !important;
        height: 70px !important;

        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .speaker-img-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13;
        font-weight: 900;
        width: 100%;
        color: #000;
        margin-bottom: 7px;
        word-spacing: 0.2px;
      }
      .speaker-img-Role {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #424242;
      }
      .speaker-img-description {
        margin-top: 2%;
        font-size: 11px;
        color: #000;
        word-wrap: inherit;
        word-spacing: 0.2px;
      }
      .speaker-img-text {
        width: 100%;
      }
    }
  }
}

/* In between tablet and pc */

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .speaker-container-main-card {
    width: 230px;
    height: 180px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    .speaker-container-main {
      .speaker-image {
        width: 50px !important;
        height: 50px !important;

        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .speaker-img-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13;
        font-weight: 900;
        width: 100%;
        color: #000;
        margin-bottom: 7px;
        word-spacing: 0.2px;
      }
      .speaker-img-Role {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #424242;
      }
      .speaker-img-description {
        margin-top: 2%;
        font-size: 11px;
        color: #000;
        word-wrap: inherit;
        word-spacing: 0.2px;
      }
      .speaker-img-text {
        width: 100%;
      }
    }
  }
}

/* tablet */

@media screen and (max-width: 768px) {
  .swiper-slide {
    width: 300px !important;
  }
  .speaker-container-main-card {
    width: 230px;
    height: 180px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    .speaker-container-main {
      .speaker-image {
        width: 50px !important;
        height: 50px !important;

        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .speaker-img-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13;
        font-weight: 900;
        width: 100%;
        color: #000;
        margin-bottom: 7px;
        word-spacing: 0.2px;
      }
      .speaker-img-Role {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #424242;
      }
      .speaker-img-description {
        margin-top: 2%;
        font-size: 11px;
        color: #000;
        word-wrap: inherit;
        word-spacing: 0.2px;
      }
      .speaker-img-text {
        width: 100%;
      }
    }
  }
}

/* smart phone */
@media (min-width: 169px) and (max-width: 480px) {
  .speaker-container-main-card {
    width: 230px;
    height: 180px;
    border: 1px solid #f68a20;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    .speaker-container-main {
      .speaker-image {
        width: 50px !important;
        height: 50px !important;

        margin-bottom: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .speaker-img-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13;
        font-weight: 900;
        width: 100%;
        color: #000;
        margin-bottom: 7px;
        word-spacing: 0.2px;
      }
      .speaker-img-Role {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        color: #424242;
      }
      .speaker-img-description {
        margin-top: 2%;
        font-size: 11px;
        color: #000;
        word-wrap: inherit;
        word-spacing: 0.2px;
      }
      .speaker-img-text {
        width: 100%;
      }
    }
  }
}
