//desktop

//my big screen Landing

@media (min-width: 1281px) {
  .new-event-registration-page {
    width: 100%;
    .new-event-registration-page-banner {
      background-color: #fff !important;
    }
  }
}

// small laptop screen
@media (min-width: 1100px) and (max-width: 1280px) {
  .new-event-registration-page {
    width: 100%;
    .new-event-registration-page-banner {
      background-color: #fff !important;
    }
  }
}

/* Tablets Screen */
@media (min-width: 481px) and (max-width: 1099px) {
  .new-event-registration-page {
    width: 100%;
    .new-event-registration-page-banner {
      background-color: #fff !important;
    }
  }
}

//smart phones
@media (min-width: 150px) and (max-width: 480px) {
  .new-event-registration-page {
    width: 100%;
    .new-event-registration-page-banner {
      width: 100% !important;
      height: 170px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
