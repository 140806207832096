.otp-input-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  outline: none;
}

.otp-input.active {
  border-color: #f68a20;
}
