.ai-chat {
  display: flex;
  flex-direction: column;
  height: 85vh;
  background-color: #faf8f8;

  .chat-header {
    background-color: #f68a20;
    color: #fff;
    padding: 1rem;
    text-align: center;

    h1 {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .chat-body {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;

    .message {
      margin-bottom: 1rem;

      &.user {
        text-align: right;

        .message-content {
          background-color: #f68a20;
          color: white;
          padding: 0.5rem;
          border-radius: 10px;
          display: inline-block;
        }
      }

      &.ai {
        text-align: left;

        .message-content {
          background-color: #e0e0e0;
          color: #000;
          padding: 0.5rem;
          border-radius: 10px;
          display: inline-block;
        }
      }
    }

    .typing {
      display: inline-block;
      background-color: #e0e0e0;
      color: #000;
      padding: 0.5rem;
      border-radius: 10px;
    }

    .typing-animation {
      animation: typing 1.5s infinite;
    }

    @keyframes typing {
      0% {
        opacity: 0.3;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.3;
      }
    }
  }

  .chat-input {
    background-color: white;
    padding: 1rem;
    display: flex;
    justify-content: center;

    .input-container {
      display: flex;
      width: 100%;

      input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px 0 0 5px;
        outline: none;

        &:focus {
          border-color: #f68a20;
        }
      }

      button {
        background-color: #f68a20;
        color: white;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 0 5px 5px 0;
        cursor: pointer;

        &:hover {
          background-color: #f68a20;
        }
      }
    }
  }
}
