//
// _footer.scss
//

.footer-custom {
  width: 100%;
  bottom: 0;
  padding: 10px !important;
  position: fixed;
  right: 0;
  color: #fff;
  left: 0;
  height: 50px;
  background-color: #000;
  z-index: 1000;
  .footer-right-content {
    width: 100%;
    display: flex;
  }
  .footer-left-content {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

.footer {
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: $sidebar-width;
  height: $footer-height;
  box-shadow: $box-shadow;
  background-color: $footer-bg;
  z-index: 1000;
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}

//table
@media (min-width: 566px) and (max-width: 820px) {
  .footer-custom {
    width: 100%;
    bottom: 0;
    padding: 10px !important;
    position: fixed;
    right: 0;
    color: #fff;
    left: 0;
    height: 50px;
    background-color: #000;
    z-index: 1000;
    .footer-right-content {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .footer-left-content {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

//smart phones
@media (min-width: 150px) and (max-width: 565px) {
  .footer-custom {
    width: 100%;
    bottom: 0;
    padding: 10px !important;
    position: fixed;
    right: 0;
    color: #fff;
    left: 0;
    height: 50px;
    background-color: #000;
    z-index: 1000;
    .footer-right-content {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .footer-left-content {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
