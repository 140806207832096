@media screen and (max-width: 480px) {
  .coming-soon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-wrapper {
      width: 100%;
      margin: 0px auto;
    }
    &-title {
      font-size: 24px;
      font-family: "Poppins";
      margin-bottom: 0px;
      line-height: 1;
      font-weight: 700;
      color: white;
      margin-bottom: 3%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 480px) {
  .coming-soon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-wrapper {
      width: 100%;
      margin: 0px auto;
    }
    &-title {
      font-size: 30px;
      font-family: "Poppins";
      margin-bottom: 0px;
      line-height: 1;
      font-weight: 700;
      color: white;
      margin-bottom: 3%;
    }
  }
}

@media screen and (min-width: 769px) {
  .coming-soon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-wrapper {
      width: 60%;
      margin: 0px auto;
    }
    &-title {
      font-size: 60px;
      font-family: "Poppins";
      margin-bottom: 0px;
      line-height: 1;
      font-weight: 700;
      color: white;
      margin-bottom: 4%;
    }
  }
}
