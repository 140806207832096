/* .radio-label {
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.radio-input {
  display: none;
}

.radio-label.active {
  background-color: #f68a20;
  border-color: #f68a20;
  color: white;
}

/* Hover effect for radio labels */
/* .radio-label:hover {
  background-color: #f68a20;
  border-color: #f68a20;
  color: white;
}  */

.radio-input:checked + .radio-label {
  color: orange;
}

.radio-input {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: inline-block;
  position: relative;
}

.radio-input:checked {
  background-color: orange;
  border-color: orange;
}
