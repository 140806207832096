$primary: #f68a20;
$grey: #e0e0e0;

.ticket {
  display: flex;
  // font-family: Roboto;
  margin: 16px;
  border: 1px solid $grey;
  position: relative;

  &:before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid $grey;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    transform: rotate(-45deg);
    left: -18px;
    top: 50%;
    margin-top: -16px;
    border-radius: 50%;
  }

  &:after {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid $grey;
    border-top-color: transparent;
    border-left-color: transparent;
    position: absolute;
    transform: rotate(135deg);
    right: -18px;
    top: 50%;
    margin-top: -16px;
    border-radius: 50%;
  }

  &--right--container {
    width: 30px;
  }

  &--start {
    position: relative;
    &:before {
      content: "";
      width: 32px;
      height: 32px;
      background-color: #fff;
      border: 1px solid $grey;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      position: absolute;
      transform: rotate(-45deg);
      left: -18px;
      top: -2px;
      margin-top: -16px;
      border-radius: 50%;
    }

    &:after {
      content: "";
      width: 32px;
      height: 32px;
      background-color: #fff;
      border: 1px solid $grey;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      transform: rotate(-45deg);
      left: -18px;
      top: 100%;
      margin-top: -16px;
      border-radius: 50%;
    }
    // & > img {
    //   display: block;
    //   padding: 24px;
    //   height: 270px;
    // }
    // border-right: 1px dashed $grey;
  }

  &--center {
    padding: 24px;
    flex: 1;
    &--row {
      display: flex;
      &:not(:last-child) {
        padding-bottom: 48px;
      }

      &:first-child {
        span {
          color: $primary;
          text-transform: uppercase;
          line-height: 24px;
          font-size: 13px;
          font-weight: 500;
        }

        strong {
          font-size: 20px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }

    &--col {
      display: flex;
      flex: 1;
      width: 50%;
      box-sizing: border-box;
      flex-direction: column;
      &:not(:last-child) {
        padding-right: 16px;
      }
    }
  }

  &--end {
    padding: 24px;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    position: relative;
    &:before {
      content: "";
      width: 32px;
      height: 32px;
      background-color: #fff;
      border: 1px solid $grey;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      transform: rotate(-45deg);
      right: -18px;
      top: -2px;
      margin-top: -16px;
      border-radius: 50%;
    }

    &:after {
      content: "";
      width: 32px;
      height: 32px;
      background-color: #fff;
      border: 1px solid $grey;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      transform: rotate(-45deg);
      right: -18px;
      top: 100%;
      margin-top: -16px;
      border-radius: 50%;
    }

    & > div {
      &:first-child {
        flex: 1;
        & > img {
          width: 128px;
          padding: 4px;
          background-color: #fff;
        }
      }

      &:last-child {
        > img {
          display: block;
          margin: 0 auto;
          filter: brightness(0) invert(1);
          opacity: 0.64;
        }
      }
    }
  }

  &--info {
    &--title {
      text-transform: uppercase;
      color: #757575;
      font-size: 13px;
      line-height: 24px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--earlyBird--title {
      text-transform: uppercase;
      color: #575757;
      font-size: 13px;
      line-height: 24px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--subtitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $primary;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--content {
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
