//
// Extras pages.scss
//

/*********************
    Faqs
**********************/

.gallery-box {
  a {
    .gallery-content {
      border-radius: 4px;
      overflow: hidden;
    }
    .gallery-overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $primary;
      opacity: 0;
      transition: all 0.5s;
    }

    .overlay-content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -60px;
      background-color: $card-bg;
      transition: all 0.5s;
      padding: 16px 12px 12px 12px;
      margin: 0px 12px;
      border-radius: 4px;
    }

    .gallery-overlay-icon {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-85%);
      transition: all 0.5s;
      i {
        font-size: 28px;
      }
    }

    &:hover {
      .gallery-overlay {
        opacity: 0.7;
      }

      .overlay-content {
        position: absolute;
        bottom: 12px;
      }
    }
  }
}

.error-text {
  text-shadow: 4px 4px rgba($primary, 0.4);
}
