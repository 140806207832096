/* Media query for mobile screens (below 1100px) */
.company-details-booth-container {
  width: 880px;
  &-header {
    display: flex;
    &-img {
      width: 155px;
      height: 80px;
      border-radius: 10px;
      border: 1px solid #f68a20;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    &-text {
      margin-left: 10px;
      &-headLine {
        font-size: 16px;
        font-weight: 600;
        color: #f68a20;
      }
      &-link {
        display: flex;
        align-items: center;
        gap: 1;
        font-weight: bold;
      }
      &-social {
        margin-top: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}

/* Media query for mobile screens (below 1000px) */
@media (max-width: 1000px) {
  .company-details-booth-container {
    width: 770px;
    &-header {
      display: flex;
      &-img {
        width: 195px;
        height: 120px;
        border-radius: 10px;
        border: 1px solid #f68a20;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &-text {
        margin-left: 10px;
        &-headLine {
          font-size: 16px;
          font-weight: 600;
          color: #f68a20;
        }
        &-link {
          display: block;
        }
        &-social {
          margin-top: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

/* Media query for mobile screens (below 768px) */
@media (max-width: 768px) {
  .company-details-booth-container {
    width: 770px;
    &-header {
      display: flex;
      &-img {
        width: 195px;
        height: 120px;
        border-radius: 10px;
        border: 1px solid #f68a20;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &-text {
        margin-left: 10px;
        &-headLine {
          font-size: 16px;
          font-weight: 600;
          color: #f68a20;
        }
        &-link {
          display: block;
        }
        &-social {
          margin-top: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .company-details-booth-container {
    width: 100%;
    // border: 3px solid rgb(1, 158, 100);
    &-header {
      display: block;
      &-img {
        width: 195px;
        height: 120px;
        border-radius: 10px;
        border: 1px solid #f68a20;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      &-text {
        margin-left: 10px;
        &-headLine {
          font-size: 16px;
          font-weight: 600;
          color: #f68a20;
        }
        &-link {
          display: block;
        }
        &-social {
          margin-top: 10px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
