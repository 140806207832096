.home {
  background-color: #f9c393; // Main background color
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; // Adding padding for better spacing
}

.home__header {
  margin: 20px 0;
  text-align: center;

  h1 {
    color: #1c213a;
    font-weight: 700; // Increased weight for prominence
    font-size: 3rem; // Responsive size
    line-height: 1.2;
  }

  p {
    color: rgb(98, 100, 115);
    font-weight: 400;
    font-size: 1.25rem; // Responsive size
    line-height: 1.5;
    max-width: 600px; // Limit width for better readability
    margin: 0 auto; // Center text
  }
}

.home__features {
  margin: 40px 0;
  text-align: center;

  h2 {
    font-size: 2.5rem; // Larger font size for emphasis
    color: #232633;
    margin-bottom: 20px;
    text-decoration: underline; // Added underline for visual interest
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex; // Use flexbox for better layout
    justify-content: center; // Center items
    flex-wrap: wrap; // Wrap items if necessary
    gap: 20px; // Space between items
    
    li {
      background-color: #fff; // White background for contrast
      border-radius: 8px; // Rounded corners
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      padding: 20px;
      width: 250px; // Fixed width for uniformity
      text-align: center;
      font-size: 1.1rem;
      color: #4b4d5f;
      transition: transform 0.3s; // Transition for hover effect

      &:hover {
        transform: translateY(-5px); // Lift effect on hover
      }
    }
  }
}

.home__call-to-action {
  margin: 40px 0;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    color: #232633;
    margin-bottom: 20px;
  }

  .btn {
    border: none;
    border-radius: 16px;
    height: 56px;
    font-size: 16px !important;
    font-weight: 600;
    display: inline-block;
    padding: 0 24px;
    background-color: #232633;
    color: #fff;
    letter-spacing: 0.8px;
    margin: 0 10px; // Space between buttons
    transition: background-color 0.3s, transform 0.3s; // Transition effects

    &.btn-secondary {
      background-color: #fff;
      color: #232633;

      &:hover {
        background-color: #eaeaea; // Lighten on hover
      }
    }

    &:hover {
      background-color: #1a1c23; // Darken on hover
      transform: translateY(-3px); // Lift effect on hover
    }
  }
}

.home__recent-events {
  margin: 40px 0;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    color: #232633;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    color: #4b4d5f;
    margin-bottom: 20px;
  }

  // Placeholder for recent events, can be styled as cards later
  .event-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: 300px;
    display: inline-block; // Inline-block for horizontal layout
    vertical-align: top; // Align top for event cards
  }
}

.home-brandMark {
  margin: 20px 0;
  text-align: center;

  a {
    color: #232633;
    text-decoration: none;
    font-weight: 400;
  }
}
