.faq-demo-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

.faq-demo-item {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: box-shadow 0.3s ease;
}

.faq-demo-item.active {
  z-index: 2;
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 0), 0px 6px 10px rgba(0, 0, 0, 0.05),
    0px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border-bottom: none;
}

.faq-demo-question {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.faq-demo-icon {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid rgb(0, 0, 0);
  user-select: none;
}

.faq-demo-answer {
  padding: 15px 20px;
  font-size: 16px;
  line-height: 1.5;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.faq-demo-item.active + .faq-demo-item {
  margin-top: -10px;
}

.faq-demo-item.active {
  margin-bottom: 10px;
}
