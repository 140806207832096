$color: white;
$primary: #f68a20;
$waves: #ff0000;
// $waves: #f68a20;
// $waves: #4aa3ff;

.btn-exhibition-lobby {
  background: $primary;
  cursor: pointer;
  border: 2px solid $primary;
  color: white;
  height: 40px;
  width: 130px;
  text-align: center;
  display: flex;
  //   flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  //   text-transform: uppercase;
  font-family: "Poppins";
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  animation: ripple 1.5s linear infinite;
  transition: all 0.7s ease;

  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($waves, 0.3), 0 0 0 1px rgba($waves, 0.3),
      0 0 0 3px rgba($waves, 0.3), 0 0 0 5px rgba($waves, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($waves, 0.3), 0 0 0 4px rgba($waves, 0.3),
      0 0 0 20px rgba($waves, 0), 0 0 0 30px rgba($waves, 0);
  }
}

.lobby-booth-reception {
  //   background-color: red;
  height: 84vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

// booths

.btn-booth-booths-container {
  width: 100%;
  // margin-top: 7%;
}
.btn-booth-booths-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.booth-search-container {
  width: 100%;
  padding-top: 6%;
  padding-left: 3%;
}

//slide navigation style
.booth-swipper-slides .swiper-button-next,
.booth-swipper-slides .swiper-button-prev {
  color: #f68a20 !important;
}

// .booth-reception-main {
// }

.booth-reception-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}
.booth-reception-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent;

  &-img {
    width: 500px;
    height: 100%;
    position: relative;
    .company-exhibition-lobby-exhibitor {
      position: absolute;
      width: 49px;
      height: 49px;
      top: 24%;
      left: 18%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .company-exhibition-lobby {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 24%;
      left: 18%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .company-name-lobby {
      position: absolute;
      color: white;
      font-weight: 500;
      font-size: small;
      top: 27%;
      right: 18%;
    }
    .btn-enter-booth {
      position: absolute;
      bottom: 30%;
      left: 35%;
    }
  }
}

/* Media query for mobile screens (below 768px) */
@media (max-width: 768px) {
  .booth-search-container {
    width: 100%;
    padding-top: 20%;
    padding-left: 5%;
  }
}
