.marquee-inner {
  display: flex;
  gap: 10; /* Adjust the gap between items if needed */
}

.sponsor-item {
  width: 70px;
  height: 60px;
}

.marquee-inner img {
  display: block;
}
